<template>
  <div>
    <div>
      <div class="card my-2">
        <div class="card-body d-flex justify-content-between">
          <div>
            Total selecionados: {{ selecteds.length }} |
            {{ selecteds.reduce((r, v) => (r += v.valor), 0) | currency }}
          </div>
          <div style="">
            <b-btn
              small
              class="mr-2"
              size="sm"
              variant="danger"
              :disabled="selecteds.length <= 0"
              @click="delLista(selecteds)"
              >Excluir Selecionados</b-btn
            >
            <b-btn
              small
              size="sm"
              v-if="selecteds.filter((x) => x.consolidado !== 'S').length > 0"
              variant="info"
              @click="consolidarLista(selecteds, 1)"
              >Consolidar Selecionados</b-btn
            >

            <b-btn
              small
              size="sm"
              v-if="selecteds.filter((x) => x.consolidado === 'S').length > 0"
              variant="secondary"
              @click="consolidarLista(selecteds, 0)"
              >DesConsolidar Selecionados</b-btn
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-between">
          <div>
            <b-btn
              variant="info"
              :block="$store.state.isMobile"
              @click="adicionarMovimento"
              ><b-icon-layout-text-sidebar-reverse /> Nova Movimentação</b-btn
            >
            <b-btn
              variant="warning"
              :block="$store.state.isMobile"
              class="ml-1"
              @click="transferencia"
              ><b-icon-arrow-left-right /> Transferência</b-btn
            >
            <b-btn
              :block="$store.state.isMobile"
              variant="secondary"
              @click="carregar"
              class="ml-1"
              ><i class="fa fa-sync-alt" /> Atualizar
            </b-btn>
          </div>
          <div class="d-flex">
            <b-input
              v-model="filterr"
              style="max-width: 100px"
              class="mr-2"
              placeholder="Filtrar"
            >
            </b-input>

            <v-date-picker
              v-model="dateRange"
              is-range
              class="text-center mr-2"
              style="min-width: 190px"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <div class="flex justify-center items-center">
                  <input
                    :value="`${inputValue.start} - ${inputValue.end}`"
                    v-on="inputEvents.start"
                    class="form-control"
                  />
                </div>
              </template>
            </v-date-picker>

            <b-select
              style="width: 110px"
              :options="['Todos', 'Consolidados', 'Á Consolidar']"
              v-model="tipoFiltro"
              @change="carregar"
            />
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="py-3 d-flex justify-content-between flex-wrap">
            <div class="">
              <b-checkbox
                @change="selecionaTudo"
                v-model="selected.all"
                :indeterminate="selecteds.length > 0"
                >Selecionar Tudo</b-checkbox
              >
              <b-checkbox
                class="text-success"
                :indeterminate="selectedCreditos.length > 0"
                v-model="selected.creditos"
                @change="selecionaCreditos"
                >Selecionar Créditos</b-checkbox
              >
              <b-checkbox
                class="text-danger"
                :indeterminate="selectedDebitos.length > 0"
                v-model="selected.debitos"
                @change="selecionaDebitos"
              >
                Selecionar Débitos</b-checkbox
              >
            </div>
            <div
              class="d-flex flex-wrap pl-5 align-end justify-content-end"
              style="flex: 1"
            >
              <div
                class="mr-4"
                :style="
                  filtredCustos && filtredCustos.length > 0 ? `width:100%` : ``
                "
              >
                <select-centro-custo
                  key="as"
                  :showTitle="true"
                  :multiple="true"
                  @change="(ev) => (filtredCustos = ev)"
                />
              </div>
              <div
                class="mr-4"
                :style="
                  filtredFornecedores && filtredFornecedores.length > 0
                    ? `max-width: 300px`
                    : ``
                "
              >
                <select-fornecedor
                  key="ad"
                  :showTitle="true"
                  :multiple="true"
                  :showManager="false"
                  @change="(ev) => (filtredFornecedores = ev)"
                />
              </div>
              <div
                class="mr-4"
                :style="
                  filtredPlanoContas && filtredPlanoContas.length > 0
                    ? `max-width: 300px`
                    : ``
                "
              >
                <select-plano-contas
                  key="at"
                  :showTitle="true"
                  :multiple="true"
                  @change="(ev) => (filtredPlanoContas = ev)"
                />
              </div>
              <div class="ml-4">
                <b-icon-arrow-down-up /> Tipo Movimento
                <v-select
                  style="width: 190px"
                  multiple
                  v-model="showCredDeb"
                  :options="[`Crédito`, `Débito`]"
                >
                </v-select>
              </div>
            </div>
          </div>
          <div v-if="loading" class="text-center p-5">
            <b-spinner /><br /><br />
            aguarde...
          </div>
          <div class="text-right" v-if="saldos && saldos.saldo_anterior">
            Saldo até {{ dateRange.start | moment("DD/MM/YYYY") }} -
            {{ saldos.saldo_anterior | currency }}
          </div>

          <b-table
            v-if="!loading"
            small
            hover
            stacked="sm"
            :filter="filterr"
            :items="sortedItems"
            :current-page="currentPage"
            :per-page="perPage"
            :sortDirection="sortDirection"
            :sortKey="sortKey"
            @head-clicked="
              (ev) => {
                sortKey = ev;
                sortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
              }
            "
            selectable
            select-mode="multi"
            style="font-size: 11px"
            :fields="fields"
            @row-clicked="select"
          >
            <template #head(sel)>
              <b-form-checkbox
                v-model="selectPage"
                @change="selectAllFromPage"
              ></b-form-checkbox>
            </template>
            <template #head(concBT)>
              <div class="text-right">
                <b-btn small size="sm" variant="info" @click="FieldsAvaiables">
                  <b-icon-eye />
                </b-btn>
              </div>
            </template>
            <template #cell(fornecedor_id)="row">
              {{ row.item.fornecedor }}
            </template>
            <template #cell(cod_custo)="row">
              {{ row.item.centro_custo }}
            </template>

            <template #cell(sel)="row">
              <b-form-checkbox
                v-model="row.item.selected"
                :value="true"
              ></b-form-checkbox>
            </template>
            <template #cell(conc)="row">
              <div class="text-left">
                <h6 class="p-0 m-0" v-if="row.item.consolidado == 'S'">
                  <b-icon-check v-if="row.item.consolidado == 'S'" />
                  <b-icon-x v-if="row.item.consolidado != 'S'" />
                  <small>
                    {{
                      row.item.dt_consolidado | moment("DD/MM/YYYY HH:mm:ss")
                    }}
                    <br />
                    <b-icon-person-badge class="mr-1" /> {{ row.item.User }}
                  </small>
                </h6>
                <div v-else>Não</div>
              </div>
            </template>
            <template #cell(historico)="row">
              <span
                :class="{
                  'text-success': row.item.tipo == 1,
                  'text-danger': row.item.tipo == -1,
                }"
                >{{ row.item.historico }}</span
              >
              <small v-if="row.item.PlanoContas">
                <br /><i class="fas fa-funnel-dollar mr-1" />
                {{ row.item.PlanoContas }}
              </small>
              <span v-if="row.item.ligacao">
                <i class="fas fa-exchange-alt ml-2 mr-2"></i>
                <i class="fa fa-wallet mr-1" />
                {{ row.item.ligacao.Carteira }}
              </span>
            </template>
            <template #cell(data)="row" class="text-center">
              {{ row.item.data | moment("DD/MM") }}
            </template>
            <template #cell(valor)="row" class="text-right">
              <div
                :class="{
                  'text-success': row.item.tipo == 1,
                  'text-danger': row.item.tipo == -1,
                }"
                style="font-size: 12px"
              >
                <b>{{ row.item.valor | currencyMask }}</b>
              </div>
            </template>
            <template #cell(concBT)="row">
              <div class="m-0 text-right" style="min-width: 140px">
                <b-btn
                  size="sm"
                  variant=""
                  v-if="row.item.consolidado === 'S'"
                  @click="consolidar(row.item, 0)"
                  ><b-icon-x
                /></b-btn>
                <b-btn
                  size="sm"
                  variant="success"
                  v-if="row.item.consolidado !== 'S'"
                  @click="consolidar(row.item, 1)"
                  ><b-icon-check />
                </b-btn>
                <b-btn
                  size="sm"
                  class="ml-1"
                  variant="warning"
                  @click="adicionarMovimento(row.item)"
                  ><b-icon-clipboard />
                </b-btn>
                <b-btn
                  size="sm"
                  variant="danger"
                  class="ml-1"
                  @click="del(row.item)"
                  ><b-icon-trash />
                </b-btn>
                <b-btn
                  v-if="
                    row.item.contas_pagar_baixa_id 
                  "
                  size="sm"
                  variant="info"
                  class="ml-1"
                  @click="openCPS(row.item.contas_pagar_baixa_id)"
                  ><b-icon-search />
                </b-btn>
              </div>
            </template>
          </b-table>
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <b-pagination
                v-model="currentPage"
                :total-rows="lista.length"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
              <div class="ml-3 pt-2">Por Página:</div>
              <b-form-select
                style="width: 100px"
                v-model="perPage"
                :options="[10, 20, 30, 40, 50]"
                class="ml-2"
              />
            </div>
            <div class="text-right" v-if="saldos && saldos.saldo_final">
              Saldo até {{ dateRange.end | moment("DD/MM/YYYY") }} -
              {{ saldos.saldo_final | currency }}
            </div>
          </div>
        </div>
      </div>
      <div class="card my-2">
        <div class="card-body d-flex justify-content-between">
          <div>
            Total selecionados: {{ selecteds.length }} |
            {{ selecteds.reduce((r, v) => (r += v.valor), 0) | currency }}
          </div>
          <div style="">
            <b-btn
              small
              class="mr-2"
              size="sm"
              variant="danger"
              :disabled="selecteds.length <= 0"
              @click="delLista(selecteds)"
              >Excluir Selecionados</b-btn
            >
            <b-btn
              small
              size="sm"
              v-if="selecteds.filter((x) => x.consolidado !== 'S').length > 0"
              variant="info"
              @click="consolidarLista(selecteds, 1)"
              >Consolidar Selecionados</b-btn
            >

            <b-btn
              small
              size="sm"
              v-if="selecteds.filter((x) => x.consolidado === 'S').length > 0"
              variant="secondary"
              @click="consolidarLista(selecteds, 0)"
              >DesConsolidar Selecionados</b-btn
            >
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-add-movimento"
      hide-footer
      title="Adicionar/Editar Movimento"
      no-enforce-focus
    >
      <movimento-form
        @cancelar="closeMovimento"
        :carteira-id="carteiraSelecionadaId"
        :ano="anoSelecionado"
        :mes="mesSelecionado"
        :itemEdit="itemEdit"
      />
    </b-modal>
    <b-modal
      id="modal-transferenca-movimento"
      hide-footer
      title="Realizar Transferência"
      no-enforce-focus
      @hide="closeMovimento"
    >
      <transferencia-form
        @cancelar="closeMovimento"
        :carteira-id="carteiraSelecionadaId"
        :ano="anoSelecionado"
        :mes="mesSelecionado"
        :itemEdit="itemEdit"
      />
    </b-modal>
    <b-modal
      size="xl"
      id="modal-detalhes-baixa-cp"
      title="Detalhes da Baixa"
      hide-footer
      @hidden="carregar"
    >
      <contas-pagar-detalhe-baixa v-if="baixaCPID" :baixaId="baixaCPID" />
    </b-modal>
    <b-modal id="modal-show-fields" title="Campos Disponíveis" hide-footer>
      <div>
        Selecione os campos que deseja visualizar na grade
        <div class="py-4">
          <b-checkbox
            inline
            v-for="(f, idx) in avaiableFields.filter(
              (x) => typeof x.sel != 'undefined'
            )"
            :key="idx"
            v-model="f.sel"
          >
            {{ f.label }}
          </b-checkbox>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BancoMovimentoLib from "../../../libs/BancoMovimentoLib";
import MovimentoForm from "./MovimentoForm.vue";
import moment from "moment";
import TransferenciaForm from "./TransferenciaForm.vue";
import ContasPagarDetalheBaixa from "../ContasPagar/ContasPagarDetalheBaixa.vue";
import SelectPlanoContas from "../SelectPlanoContas.vue";
import SelectFornecedor from "../../Fornecedores/SelectFornecedor.vue";
import SelectCentroCusto from "../SelectCentroCusto.vue";
export default {
  components: {
    MovimentoForm,
    TransferenciaForm,
    ContasPagarDetalheBaixa,
    SelectPlanoContas,
    SelectFornecedor,
    SelectCentroCusto,
  },
  props: {
    carteiraSelecionadaId: Number,
    mesSelecionado: Number,
    anoSelecionado: Number,
  },
  mounted() {
    this.dateRange.start = new Date(
      this.anoSelecionado,
      this.mesSelecionado - 1,
      1
    );
    this.dateRange.end = new Date(this.anoSelecionado, this.mesSelecionado, 0);
    this.$nextTick(() => {
      this.carregar();
    });
  },
  data() {
    return {
      showCredDeb: ["Crédito", "Débito"],
      selected: {
        all: false,
        creditos: false,
        debitos: false,
      },
      filterr: "",
      selectPage: false,
      loading: true,
      lista: [],

      tipoFiltro: "Todos",
      itemEdit: null,
      baixaCPID: null,
      pages: 10,
      perPage: 20,
      totalPages: 0,
      currentPage: 1,
      dateRange: {
        start: new Date(),
        end: new Date(),
      },
      sortDirection: "asc",
      sortKey: "id",
      avaiableFields: [
        {
          key: "sel",
          label: "",
          class: "text-center",
          sortable: false,
        },
        {
          key: "id",
          label: "Código",
          class: "text-center",
          sortable: true,
          sel: true,
        },
        {
          key: "data",
          label: "Data",
          class: "text-center",
          sortable: true,
          sel: true,
        },
        {
          key: "conc",
          label: "Consolidado?",
          class: "text-left",
          sortable: true,
          sel: true,
        },
        { key: "historico", label: "Histórico", sortable: true },
        { key: "observacao", label: "Obs.", sel: true },
        { key: "fornecedor_id", label: "Fornecedor", sel: true },
        { key: "cod_custo", label: "Centro Custo", sel: true },
        { key: "PlanoContas", label: "Plano Contas", sel: true },
        {
          key: "valor",
          label: "Valor",
          class: "text-right",
          sortable: true,
          sel: true,
        },
        { key: "concBT", label: "", class: "text-center" },
      ],
      filtredPlanoContas: [],
      filtredCustos: [],
      filtredFornecedores: [],
      saldos: [],
    };
  },
  watch: {
    $props: {
      deep: true,
      handler() {
        //this.carregar();
        this.dateRange = {
          start: new Date(this.anoSelecionado, this.mesSelecionado - 1, 1),
          end: new Date(this.anoSelecionado, this.mesSelecionado, 0),
        };
        // this.carregar();
      },
    },
    tipoFiltro: function () {
      //this.carregar();
    },
    currentPage: function () {
      this.selectPage = this.paginated.every((item) => item.selected);
    },

    lista: {
      deep: true,
      handler() {
        this.totalPages = Math.ceil(this.lista.length / this.perPage);
      },
    },
    dateRange: {
      deep: true,
      handler() {
        this.carregar();
      },
    },
    filtredCustos: function () {
      this.currentPage = 1;
    },
    filtredFornecedores: function () {
      this.currentPage = 1;
    },
    filtredPlanoContas: function () {
      this.currentPage = 1;
    },
    showCredDeb: function () {
      this.currentPage = 1;
    },
  },
  computed: {
    fields() {
      return this.avaiableFields.filter(
        (x) => x.sel || x.key == "concBT" || x.key == "sel"
      );
    },
    selectedCreditos() {
      return this.sortedItems.filter((x) => x.tipo == 1 && x.selected);
    },
    selectedDebitos() {
      return this.sortedItems.filter((x) => x.tipo == -1 && x.selected);
    },
    selecteds() {
      return this.sortedItems.filter((x) => x.selected);
    },
    paginated() {
      return this.sortedItems.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    sortedItems() {
      return this.lista
        .filter(
          (x) =>
            this.showCredDeb.includes(x.tipo == 1 ? "Crédito" : "Débito") &&
            (this.filtredPlanoContas && this.filtredPlanoContas.length > 0
              ? this.filtredPlanoContas.includes(x.plano_id)
              : true) &&
            (this.filtredCustos && this.filtredCustos.length > 0
              ? this.filtredCustos.includes(x.cod_custo)
              : true) &&
            (this.filtredFornecedores && this.filtredFornecedores.length > 0
              ? this.filtredFornecedores.includes(x.fornecedor_id)
              : true)
        )
        .slice()
        .sort((a, b) => {
          let modifier = 1;
          if (this.sortDirection === "desc") modifier = -1;
          if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
          if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
          return 0;
        });
    },
  },
  methods: {
    FieldsAvaiables() {
      this.$bvModal.show("modal-show-fields");
    },
    selecionaDebitos() {
      if (this.selectedDebitos.length > 0) {
        this.selectedDebitos.forEach((item) => {
          item.selected = false;
        });
        this.selected.debitos = false;
        return;
      }
      this.selected.debitos = true;
      this.lista
        .filter((x) => x.tipo == -1)
        .forEach((item) => {
          item.selected = true;
        });
    },
    selecionaCreditos() {
      if (this.selectedCreditos.length > 0) {
        this.selectedCreditos.forEach((item) => {
          item.selected = false;
        });
        this.selected.creditos = false;
        return;
      }
      this.selected.creditos = true;
      this.lista
        .filter((x) => x.tipo == 1)
        .forEach((item) => {
          item.selected = true;
        });
    },
    selecionaTudo() {
      console.log(123);
      if (this.selecteds.length > 0) {
        this.lista.forEach((item) => {
          item.selected = false;
        });
        this.selected.all = false;
        return;
      }
      this.selected.all = true;

      this.lista.forEach((item) => {
        item.selected = true;
      });
    },
    selectAllFromPage() {
      this.paginated.forEach((item) => {
        item.selected = this.selectPage;
      });
    },
    select(it) {
      if (it) {
        it.selected = !it.selected;
      }
    },

    openCPS(itens) {
      // if (itens && itens.length > 0) {
      //   console.log("opening", itens);
      //   this.baixaCPID = itens[0].id;
      // }
      this.baixaCPID = itens
      this.$bvModal.show("modal-detalhes-baixa-cp");
    },
    async carregar() {
      console.log(this.mesSelecionado, this.anoSelecionado);
      this.loading = true;
      try {
        let saldos = await BancoMovimentoLib.getExtrato({
          dt_i: moment(this.dateRange.start).format("YYYY-MM-DD"),
          dt_f: moment(this.dateRange.end).format("YYYY-MM-DD"),
          carteiras: [this.carteiraSelecionadaId],
          noList: true,
        });
        if (saldos && saldos.data) {
          this.saldos = saldos.data;
        }

        console.log("saldos", this.saldos);

        this.lista = (
          await BancoMovimentoLib.getMovimento({
            dateRange: this.dateRange,
            carteira: this.carteiraSelecionadaId,
            naoConsolidados: true,
            NoDetails: true,
          })
        ).data
          .map((it) => {
            return {
              ...it,
              _rowVariant: it.consolidado === "S" ? "secondary" : "",
              selected: false,
            };
          })
          .filter((x) =>
            this.tipoFiltro == "Consolidados"
              ? x.consolidado == "S"
              : this.tipoFiltro == "Á Consolidar"
              ? x.consolidado != "S"
              : true
          );
        // console.log(
        //   `lista`,
        //   this.lista,
        //   lista,
        //   this.lista.filter((x) => !lista.includes((d) => d.id == x.id))
        //     .length > 0
        // );

        // for (let l of this.lista) {
        //   let has = lista.find((x) => x.id == l.id);
        //   if (!has) {
        //     this.lista.splice(this.lista.indexOf(l), 1);
        //   }
        // }

        // for (let l of lista) {
        //   let has = this.lista.find((x) => x.id == l.id);
        //   if (!has) {
        //     this.lista.push(l);
        //   } else {
        //     Object.assign(has, l);
        //   }
        // }

        console.log("lista", this.lista);
      } catch (err) {
        console.log("erro ao buscar lista mov", err);
      }
      this.loading = false;
    },
    adicionarMovimento(item) {
      this.itemEdit = item;
      this.$bvModal.show("modal-add-movimento");
    },
    closeMovimento() {
      this.$bvModal.hide("modal-add-movimento");
      this.$bvModal.hide("modal-transferenca-movimento");
      this.carregar();
      this.$emit("atualizar");
      this.$root.$emit("atualizaListaDeCarteiras");
    },
    async consolidarLista(lista, tp) {
      for (
        let i = 0;
        i <
        lista.filter(
          (x) => x.selected === true && x.consolidado == (tp == 0 ? "S" : "N")
        ).length;
        i++
      ) {
        const element = lista[i];
        await this.consolidar(element, tp);
      }
    },
    async consolidar(obj, tp) {
      if (tp == 1) {
        obj.dt_consolidado = moment().format("YYYY-MM-DD HH:mm:ss");
        obj.consolidado = "S";
        obj._rowVariant = "secondary";
      } else {
        obj.dt_consolidado = null;
        obj.consolidado = "N";
        obj._rowVariant = "";
      }
      this.$swal({
        title: "Concluído",
        text: "Movimento salvo com sucesso!",
        icon: "success",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 500,
      });
      await BancoMovimentoLib.consolidarMovimento(obj);
      this.$emit("atualizar");
      this.$root.$emit("atualizaListaDeCarteiras");
      // this.carregar();
    },
    edit(item) {
      this.$emit("editMov", item);
    },
    async delLista(lista) {
      this.$swal({
        title: "Tem certeza?",
        text: "Deseja excluir esta movimentação?",
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim. Excluir!",
        cancelButtonText: "Não.",
      }).then(async (res) => {
        if (res.isConfirmed) {
          for (let it of lista.filter((x) => x.selected === true)) {
            await BancoMovimentoLib.delMovimento(it.id);
            this.$emit("atualizar");
            // this.$swal("Excluido com sucesso", "", "success");
          }
          this.$root.$emit("atualizaListaDeCarteiras");
          this.carregar();
        }
      });
    },
    del(item) {
      this.$swal({
        title: "Tem certeza?",
        text: "Deseja excluir esta movimentação?",
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim. Excluir!",
        cancelButtonText: "Não.",
      }).then(async (res) => {
        if (res.isConfirmed) {
          await BancoMovimentoLib.delMovimento(item.id);
          this.$emit("atualizar");
          this.$root.$emit("atualizaListaDeCarteiras");
          // this.$swal("Excluido com sucesso", "", "success");
          this.carregar();
        }
      });
    },
    transferencia() {
      this.$bvModal.show("modal-transferenca-movimento");
    },
  },
};
</script>

<style lang="scss" scoped></style>
